import { Box, Flex } from "@chakra-ui/react";
import FavoritesButton from "@components/FavoritesButton.component";
import ProductImage from "@components/ProductList/ProductImage.component";
import ProductTeaserInfo from "@components/ProductList/ProductTeaserInfo.component";
import { SearchVariantModel } from "@modules/Search/Search.model";
import { useLocale, useTranslations } from "next-intl";
import { useEffect, useState } from "react";

function SearchResultCard(
  props: SearchVariantModel & {
    priority?: boolean;
    hidePrice?: boolean;
    isSearchPage?: boolean;
  }
) {
  const locale = useLocale();
  const isJp = locale === "ja-jp";
  const itemDict = useTranslations("ProductListItem");
  const specs = [
    props.material,
    props.surface,
    props.color,
    props.fabricName,
    props.materialArmBack,
    props.frame,
  ]
    .filter((i) => i)
    .map((x) => x.charAt(0) + x.slice(1))
    .join(" | ");

  // @todo portrait/landscape images in search etc. don't align

  // Check if device is touch device. If so, do not preload hover image
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    if ("ontouchstart" in window || navigator.maxTouchPoints > 0) {
      setIsTouchDevice(true);
    }
  }, []);

  const localizedFromPrice = isJp
    ? `${props.formattedLowestPrice} ${itemDict("From")} `
    : `${itemDict("From")} ${props.formattedLowestPrice}`;

  const localizedPriceWithoutFrom = props.formattedLowestPrice;
  const localizedPrice = props.isSearchPage ? localizedPriceWithoutFrom : localizedFromPrice;

  const sizes =
    props.variantImageSize === "wide"
      ? `(max-width: 768px) 100vw,
         (max-width: 992px) 66vw,
         600px`
      : `(max-width: 768px) 50vw,
         (max-width: 992px) 33vw,
         300px`;
  return (
    <Box>
      <Box display="block" bg="brand.gray.300" position="relative">
        <Flex position="absolute" right={4} top={5} zIndex={1}>
          <FavoritesButton showText={false} id={props.sku} />
        </Flex>
        <ProductImage
          image={{
            AlternativeText: props.model?.friendlyModelName,
            Title: props.model?.modelName,
            Url: props.frontImageUrl,
          }}
          splash={props.splash}
          url={props.variantPageUrl}
          format={props.variantImageSize}
          priority={props.priority}
          sizes={sizes}
          galleryImage={
            !isTouchDevice && props?.model?.galleryImage
              ? {
                AlternativeText: props?.model?.friendlyModelName,
                Title: props?.model?.modelName,
                Url: props?.model?.galleryImage.Url,
                FocalPoint: props?.model?.galleryImage?.FocalPoint
              }
              : null
          }
        />
      </Box>
      <ProductTeaserInfo
        Sku={props.sku}
        Title={`${props.model.modelName} | ${props.model.friendlyModelName}`}
        PageUrl={props.variantPageUrl}
        FormattedConfiguration={specs}
        FormattedPrice={
          props.formattedLowestPrice ? localizedPrice : props.formattedPrice
        }
        FormattedListedPrice={props.formattedListedPrice}
        Price={props.price}
        ListedPrice={props.listedPrice}
        Currency={props.currency}
        PurchaseNotEnabled={props.purchaseNotEnabled}
        NotEnabledText={props.notEnabledText}
        hideBuyButton={props.hidePrice}
        ProductionDays={props.productionDays}
        IsPreOrder={props.isPreOrder}
        SellWithoutStock={props.model.sellWithoutStock}
        Stock={props.stock}
        variants={props.variantCount}
        designer={props.model.designerName}
        isSearchPage={props.isSearchPage}
      />
    </Box>
  );
}

export default SearchResultCard;
