import { AspectRatio } from "@chakra-ui/react";
import getConfig from "@hooks/getConfig";
import NextImage, { ImageProps } from "next/image";
import { ReactElement, useEffect, useState } from "react";
import { CalculateFocalPoint } from "@services/focalPoint.service";
import { ImageAspectRatios, ImageComponentType } from "./Image.model";

function Image({
  alt,
  src,
  aspectRatio,
  sizes,
  size = "full",
  objectFit,
  priority = false,
  quality,
  crop = false,
  style,
  focalPoint = "0.5,0.5", // Many images does not have focal point set, so this is the fallback value
  loader,
}: ImageComponentType): ReactElement {
  // We're currently not sending query parameters to Episerver,
  // as the current Epi setup makes it unable to process too many simultaneous image scaling requests
  // e.g. if entire image cache is invalidated because e.g. a new query parameter is added.
  // This means the frontend receives Epi's huge images on first load, and we can't do focalpoint cropping in the backend.
  // Instead we use objectPosition

  // const queryParams = new URLSearchParams({
  //   aspect: aspectRatio,
  //   size,
  //   center: focalPoint,
  //   mode: crop ? "crop" : "max",
  // }).toString();

  // const imageSrc = [src, queryParams].join("?");

  // Fit the focalpoint into object-position percentage syntax
  var position = CalculateFocalPoint(focalPoint);

  return (
    <AspectRatio ratio={ImageAspectRatios[aspectRatio]}>
      <CloudinaryNextImage
        src={src}
        alt={alt}
        layout="fill"
        objectFit={objectFit || "contain"}
        sizes={sizes}
        priority={priority}
        objectPosition={objectFit === "cover" ? position : "50% 50%"}
        style={{
          ...style,
          ...(aspectRatio
            ? { aspectRatio: ImageAspectRatios[aspectRatio] }
            : {}),
        }} // attempt to allow style props without removing or altering aspect ratio (specifically for productimage component to allow hover image)
        // style={{ aspectRatio: ImageAspectRatios[aspectRatio] }} // Old way of doing aspect ratio
        quality={quality}
      />
    </AspectRatio>
  );
}

export function CloudinaryNextImage(props: ImageProps) {
  const { src, loader, ...imageProps } = props;
  const { masterImageSource } = getConfig();
  const [error, setError] = useState(null);
  useEffect(() => {
    setError(null);
  }, [src]);

  if (!src) return;

  // try using this cloudinaryloader, and if it fails (origin too big for cloudinary), then disable the loader and let next fall back to its original loader with much worse performance, but no failed images
  const cloudinaryLoader = (image) => {
    if (
      image.src
        .toString()
        .toLocaleLowerCase()
        .startsWith("https://res.cloudinary.com")
    )
      return image.src;

    let cleanSrc = image.src.split("?")[0]; // remove all after query string, take the close to original to cloudinary
    if (masterImageSource) {
      // replace main source url to canonical image source to avoid duplicate images in cloudinary from different environments
      const urlParts = cleanSrc.split("/");
      cleanSrc = [masterImageSource, ...urlParts.slice(3)].join("/");
    }
    // use cloudinary resize commamnd c_scale,w_${src.width} to scale down image to with sent to the control
    return `https://res.cloudinary.com/dfwzl0ito/image/fetch/c_scale,w_${
      image.width
    },f_auto/${encodeURIComponent(cleanSrc)}`;
  };

  if (error) {
    console.log("Cloudinary origin fail on load", src);
  }
  return (
    <NextImage
      src={src}
      loader={!error ? cloudinaryLoader : loader}
      onError={setError}
      {...imageProps}
    />
  );
}

export default Image;
