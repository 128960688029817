import getConfig from "@hooks/getConfig";
import { useState } from "react";
import { useAppData } from "@hooks/useAppData";
import { PriceListEntryType, PriceListType } from "@context/AppContext/App.model";
import { GetInitialSearchRecommendations, GetRecommendations, HelloRetailProduct } from "@services/helloRetail.service"
import { _convertToSearchVariantModels, _createMinimalVariantModelForSearchResult } from "@services/helloRetail.service";
import { useClientSideState } from "@zustand/clientSideState";
import { ProductTeaserType } from "@components/ProductList/ProductList.model";
import { SearchVariantModel } from "@modules/Search/Search.model";

interface RecommendParamsModel {
  helloRetailRecomKey: string,
  defaultProducts: ProductTeaserType[]
  helloRetailSplashFilter?: string,
}

function convertHelloRetailProductToProductType(
  variant: HelloRetailProduct
) {

  const productType: ProductTeaserType = {
    Images: [{
      AlternativeText: "",
      Title: variant.title,
      Url: variant.imgUrl
    }],
    IsPreOrder: variant.extraData.isPreOrder === "True",
    ListedPrice: variant.oldPrice,
    PageUrl: variant.url,
    Price: variant.price,
    PriceFormatted: variant.priceFormatted,
    Currency: variant.currency,
    ProductionDays: parseInt(variant.extraData.productionDays),
    PurchaseNotEnabled: variant.extraData.purchaseNotEnabled === "True",
    NotEnabledText: variant.extraData.purchaseNotEnabledText,
    SellWithoutStock: variant.extraData.sellWithoutStock === "True",
    Sku: variant.productNumber,
    Splash: variant.extraData.splash,
    Stock: parseInt(variant.extraData.stock),
    FormattedPrice: variant.priceFormatted,
    FormattedListedPrice: variant.oldPriceFormatted,
    FormattedConfiguration: variant.extraData.subheadline,
    Title: variant.extraData.headline
  };
  return productType;
}

function useRecommendations(initialParams: RecommendParamsModel) {
  const { helloRetailRecomEndpoint } = getConfig();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [finalProducts, setFinalProducts] = useState<ProductTeaserType[]>(null);
  const [{ marketData: { MarketId } }] = useAppData();

  const { currentProductIds, currentCategoryIds, currentDesignerId, currentDesignerName } = useClientSideState();
  const { priceListData } = useClientSideState();

  const recommend = async (params = {}): Promise<void> => {
    try {
      setLoading(true);
      const res = await GetRecommendations(
        currentProductIds,
        currentCategoryIds,
        currentDesignerId,
        currentDesignerName,
        initialParams.helloRetailSplashFilter,
        helloRetailRecomEndpoint,
        initialParams.helloRetailRecomKey
      )
      if (!res) {
        setLoading(false);
        setFinalProducts(initialParams.defaultProducts)
        return;
      }
      let variants = res.products;
      if (variants && priceListData) {
        const priceReplacedVariants: HelloRetailProduct[] = []
        // substitute marketId and remove absolute part of URL from search result
        variants.forEach((res: HelloRetailProduct) => {
          if (res.url) {
            const urlParts = res.url.split("/")
            if (urlParts.length > 4) {
              const newUrl = ["", urlParts[3], MarketId.toLowerCase(), ...urlParts.slice(5)]
              res.url = newUrl.join("/")
            }
          }
          // substitute prices
          if (res.productNumber && priceListData.prices && Object.keys(priceListData.prices).length > 0) {
            const priceRecord: PriceListEntryType = priceListData.prices[res.productNumber]
            if (priceRecord) {
              res.oldPrice = priceRecord.listedPrice
              res.oldPriceFormatted = priceRecord.formattedListedPrice
              res.price = priceRecord.price
              res.priceFormatted = priceRecord.formattedPrice
              res.currency = priceRecord.currency
              priceReplacedVariants.push(res) // only take variants that has prices
            }
          } else {
            // No prices exist for market
            res.oldPrice = null
            res.oldPriceFormatted = null
            res.price = null
            res.priceFormatted = ""
            res.currency = null
            priceReplacedVariants.push(res)
          }
        })
        variants = priceReplacedVariants;
        let productTypes = []
        variants.forEach((res: HelloRetailProduct) => {
          productTypes.push(convertHelloRetailProductToProductType(res))
        })
        setFinalProducts(productTypes);
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
      setFinalProducts(initialParams.defaultProducts);
    }
  }
  return { recommend, finalProducts, error, loading };
}

export function useInitialSearchRecommendations(initialParams: RecommendParamsModel) {
  const { helloRetailRecomEndpoint } = getConfig();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [finalProducts, setFinalProducts] = useState<SearchVariantModel[]>(null);
  const [{ marketData: { MarketId } }] = useAppData();

  const { currentProductIds, currentCategoryIds, currentDesignerId, currentDesignerName } = useClientSideState();
  const { priceListData } = useClientSideState();

  const recommend = async (params = {}): Promise<void> => {
    try {
      setLoading(true);
      const res = await GetInitialSearchRecommendations(
        currentProductIds,
        currentCategoryIds,
        currentDesignerId,
        currentDesignerName,
        initialParams.helloRetailSplashFilter,
        helloRetailRecomEndpoint,
        initialParams.helloRetailRecomKey
      )
      if (!res) {
        setLoading(false);
        setFinalProducts(null)
        return;
      }
      let variants = res;
      if (res && priceListData) {
        const priceReplacedVariants = []
        // substitute marketId and remove absolute part of URL from search result
        res.forEach((res: SearchVariantModel) => {
          if (res.variantPageUrl) {
            const urlParts = res.variantPageUrl.split("/")
            if (urlParts.length > 4) {
              const newUrl = ["", urlParts[3], MarketId.toLowerCase(), ...urlParts.slice(5)]
              res.variantPageUrl = newUrl.join("/")
            }
          }
          // substitute prices
          if (res.sku && priceListData.prices && Object.keys(priceListData.prices).length > 0) {
            const priceRecord: PriceListEntryType = priceListData.prices[res.sku]
            if (priceRecord) {
              res.lowestVariantPrice = priceRecord.price
              res.formattedLowestPrice = priceRecord.formattedPrice
              res.price = priceRecord.listedPrice
              res.formattedPrice = priceRecord.formattedListedPrice
              res.currency = priceRecord.currency
              priceReplacedVariants.push(res) // only take variants that has prices
            }
          } else {
            // No prices exist for market
            res.lowestVariantPrice = null
            res.formattedLowestPrice = ""
            res.price = null
            res.formattedPrice = null
            res.currency = null
            priceReplacedVariants.push(res)
          }
        })
        variants = priceReplacedVariants;
      }

      setFinalProducts(variants);
    } catch (error) {
      setLoading(false);
      setError(error.message);
      setFinalProducts(null);
    }
  }
  return { recommend, finalProducts, error, loading };
}

export default useRecommendations;
